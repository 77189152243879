.activity-list-filter-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    flex: 1;

    .activity-list-property-filters {
        order: 1;
        margin-right: 1rem;
        min-width: 375px;
        max-width: 700px;
        margin-bottom: 1rem;

        .awsui-input {
            height: 32px;
        }
    }

    .activity-list-daterange-picker {
        order: 2;
    }
}