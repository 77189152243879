@import 'node_modules/@amzn/awsui-components-react/sass/constants';

.awsui {
	.multi-row-attribute-editor {
		&__divider {
			&--always-visible {
				display: block;
			}
		}

		&__row:not(:first-child) {
			.awsui-form-field-label {
				position: static;
				height: auto;
				width: auto;
			}
		}
	}

	&__empty-text {
		font-size: 1.4rem;
		line-height: 2rem;
		color: var(--awsui-color-text-empty);
	}
}
