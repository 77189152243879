.reassign-reason-form-field {
    margin-top: 20px;
}

.reassign-adjacent-activity {
    margin-top: 20px;
    display: flex;
    flex-direction: column;

    .reassign-adjacent-activity-item {
        margin-bottom: 20px;
        flex-direction: column;
        display: flex;
    }

    .reassign-adjacent-activity-title {
        color: #545b64;
    }

    .reassign-adjacent-activity-name {
        color: #0073bb;
        text-decoration: none;
    }
}

.assign-instructor-modal {
    z-index: 1500 !important;
}
