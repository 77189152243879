.highlight {
    background-color: #f1faff;
    border: solid #0073bb 1px !important;
}

.column-header {
    background-color: #fafafa;
}

.fc-datagrid-cell-frame {
    .fl-badge {
        margin-top: 0;
        margin-bottom: 0;
    }
}

.fc-timeline-event-harness {
    z-index: 2;
    margin: 6px 2px 0px 4px;

    .fc-timeline-event {
        border-radius: 2px;
    }
}

.btn-reserved-time-link {
    color: white !important;
    border: none !important;
    font-weight: normal !important;
    padding-top: 6px !important;
    padding-bottom: 6px !important;
    &:hover {
        background-color: #0073bb !important;
    }
}

.btn-unavailable-time-link {
    color: #0073bb !important;
    border: none !important;
    font-weight: normal !important;
    &:hover {
        background-color: transparent !important;
    }
}

.assign-instructor-controls {
    display: flex;
    flex-direction: row;
    gap: 10px;

    .preferences-button {
        width: 20px;
    }

    .pagination-list {
        flex: 1;
        display: flex;
        justify-content: right;
    }
}
