.h-line {
    height: 5px;
    margin-bottom: 20px;
    border-bottom: 1px solid #D5DBDB;
}

.synced-kiku {
    font-weight: normal;
    display: flex;
    flex-direction: row;
    height: 100%;
    justify-content: center;
    align-items: center;
    font-size: 12pt;
}