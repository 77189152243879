@import '@amzn/awsui-components-react/sass/constants';

@mixin first-letter-uppercase {
    text-transform: lowercase;
    &::first-letter {
        text-transform: uppercase;
    }
}

@mixin form-sub-section-header {
    font-size: 1.8rem;
    line-height: 2rem;
    background: transparent;
}

html,
body {
    margin: 0;
    padding: 0;
    height: 100%;
}

#root {
    height: 100%;
    display: flex;
    flex-direction: column;
}

.breadcrumbs .awsui-breadcrumb-link-text {
    text-transform: none !important;
}

.awsui-side-navigation__list li {
    text-transform: none !important;
}

.grimsby-header {
    display: block;
    margin: 0;
    color: $awsui-white;
    padding: 10px 10px;
    background: $awsui-aws-squid-ink;

    .awsui-row {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    &--box {
        display: flex;
        align-items: center;
    }
}

.grimsby-footer {
    display: block;
    margin: 0;
    color: $awsui-white;
    padding: 7px 20px;
    background: $awsui-aws-squid-ink;
    font-weight: 100;

    a {
        color: $awsui-white;
        margin-left: 1rem;

        &:hover {
            text-decoration: none;
        }
    }
}

.app-name {
    display: inline;
    vertical-align: top;
    color: #d5dbdb;
    font-size: 1.8rem;
    font-weight: 100;
    letter-spacing: 0.03em;
    padding: 10px;
    padding-left: 20px;
}

.app-user {
    color: $awsui-grey-200;
    font-weight: 300;

    a {
        font-weight: 400;
        color: $awsui-white;
    }
}

.app-switcher {
    .awsui-button-dropdown-wrapped {
        background: $awsui-aws-squid-ink;

        .awsui-button-dropdown-items {
            .awsui-button-dropdown-item {
                &:first-child {
                    margin-top: 1.5rem;
                    border-top: none;
                }

                border: none;
                color: $awsui-white;
                padding-top: 2rem;
                padding-bottom: 2rem;

                &:not(.awsui-button-dropdown-item-disabled) {
                    &:hover {
                        background: $awsui-grey-600;
                        color: $awsui-orange-500;
                    }
                }
            }
        }
    }
    .awsui-button.awsui-button-variant-normal {
        background: $internal-awsui-color-transparent;
        border: none;
        color: #d5dbdb;

        &[aria-expanded='true'] {
            color: $awsui-orange-500;

            &:hover {
                color: $awsui-orange-500;
            }
        }

        &:hover {
            background: $internal-awsui-color-transparent;
            border: none;
            color: #d5dbdb;
        }

        &.active {
            color: #ff9900;
        }
    }
}
.user-info {
    float: right;
    .avatar {
        width: 40px;
        height: 40px;
        border-radius: 50%;
        border: 1px solid $awsui-white;
    }
}

.user-profile {
    .profile-image {
        border: 5px solid $awsui-grey-300;
    }
}

.audit-table {
    table tbody {
        font-size: 11px;
        font-family: $awsui-font-family-monospace;
    }
    .load-more {
        margin-top: 5px;
        text-align: center;
    }
}

.breadcrumbs {
    padding: 1rem 0;

    .awsui-breadcrumb-link-text {
        text-transform: lowercase;
    }

    .awsui-breadcrumb-link::first-letter {
        text-transform: uppercase;
    }
}

.awsui-side-navigation__list {
    li {
        @include first-letter-uppercase;
    }
}

.awsui-side-navigation__title {
    @include first-letter-uppercase;
}

.nowrap {
    white-space: nowrap;
}

.saved-filters {
    &--header {
        border-bottom: 1px solid $awsui-grey-200;
    }
}

.certification-cell {
    &--name {
        width: 95%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
}

.grimsby-form-section {
    padding-bottom: 1.9rem !important;
    border-bottom: 1px solid #eaeded !important;

    &-header {
        @include form-sub-section-header;
        padding: 1.9rem 0;
        border-top: 1px solid #eaeded;
        border-bottom: none;
        font-weight: bold;
    }

    &-description {
        color: #545b64;
        font-size: 1.2rem;
        line-height: 1.5rem;
        padding-top: 0.5rem;
        font-weight: 400;
    }
}

.grimsby-sub-section-header {
    @include form-sub-section-header;
    font-weight: 500;
}

.grimsby-sub-section-divider {
    height: 1px;
    background-color: #eaeded;
    padding: 0 !important;
    margin: 1rem;
}

.awsui .awsui-select-trigger-placeholder {
    color: #626262;
}
