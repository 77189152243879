.resource-title {
    padding: 2px;
    border-bottom: 1px dashed;
    cursor: pointer;
}

.resource-quickview-footer {
    display: flex;
    flex-direction: row;

    .full-details {
        flex: 1;
        justify-content: center;
        display: flex;
        flex-direction: column;
    }
}

.resouce-content-window {
    display: flex;
    flex: 1;
    flex-direction: column;
    overflow-y: auto;
}