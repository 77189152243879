.view-links {
    background: none;
	border: none;
	padding: 0;
	font: inherit;
	outline: inherit;
    color: var(--awsui-color-text-link-default,#0073bb);
    cursor: pointer;
}

.registration-link {
    cursor: pointer;
    margin-left: 5px;
    white-space: pre-line;
}

.unstyled-button {
    background: none;
	color: inherit;
	border: none;
	padding: 0;
	font: inherit;
	cursor: pointer;
	outline: inherit;
}

.activity-link-container {
    display: flex;
    flex-direction: column;

    .link-label {
        margin-top: 15px;
        margin-bottom: 5px;
    }

    .link-url-container {
        display: flex;
        width: 100%;
        flex-direction: row;
        gap: 8px;

        .link-box {
            border: 1px solid #EAEDED;
            border-radius: 2px;
            flex: 1;
            margin-right: 20px;
            padding: 5px 9px;
            height: 32px;
            box-sizing: border-box;
            display: flex;
            flex-direction: column;
            justify-content: center;
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
        }
    }
}